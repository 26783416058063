export const tableTranslations = {
	footerRowSelected: (count: any) => (count !== 1 ? `${count.toLocaleString()} izbranih vrstic` : `${count.toLocaleString()} izbrana vrstica`),
	footerTotalVisibleRows: (visibleCount: any, totalCount: any) => `${visibleCount.toLocaleString()} od ${totalCount.toLocaleString()}`,
	columnMenuUnsort: "Ponastavi",
	columnMenuSortAsc: "Razvrsti naraščajoče",
	columnMenuSortDesc: "Razvrsti padajoče",
	columnMenuFilter: "Filtriraj",
	columnMenuHideColumn: "Skrij",
	columnMenuShowColumns: "Pokaži stolpce",
	filterOperatorContains: "vsebuje",
	filterOperatorEquals: "je enak",
	filterOperatorStartsWith: "se začne z",
	filterOperatorEndsWith: "se konča z",
	filterOperatorIs: "je",
	filterOperatorNot: "ni",
	filterOperatorAfter: "je po",
	filterOperatorOnOrAfter: "je na datum ali kasneje",
	filterOperatorBefore: "je pred",
	filterOperatorOnOrBefore: "je na datum ali prej",
	filterOperatorIsEmpty: "je prazen",
	filterOperatorIsNotEmpty: "ni prazen",
	filterOperatorIsAnyOf: "je eden izmed",
	filterPanelInputPlaceholder: "Filtriraj vrednost",
	toolbarColumns: "Stolpci",
	filterPanelColumns: "Stolpec",
	filterPanelInputLabel: "Vrednost",
};
